import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const buttonColors = (props) => {
  let colors = {};

  if (props.green) {
    colors = {
      background: props.theme.color.backgroundGreen,
      border: props.theme.color.backgroundGreen,
      color: props.theme.color.white
    };
  } else if (!props.outline) {
    if (!props.inverted) {
      colors = {
        background: props.theme.color.brand,
        border: props.theme.color.brand,
        color: props.theme.color.white
      };
    } else {
      colors = {
        background: props.theme.color.brandNeutral,
        border: props.theme.color.brandNeutral,
        color: props.theme.color.brand
      };
    }
  } else if (props.outline) {
    if (props.inverted) {
      colors = {
        background: 'transparent',
        border: props.theme.color.white,
        color: props.theme.color.white
      };
    } else {
      colors = {
        background: 'transparent',
        border: props.theme.color.brand,
        color: props.theme.color.brand
      };
    }
  }

  return colors;
};

const ButtonReset = (props) => css`
  appearance: none;
  background: transparent;
  border: none;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  ${props.isIcon ? 'display: flex;' : ''}
  font: inherit;
  letter-spacing: ${props.green ? '' : '.24em'};
  line-height: 4rem;
  margin: 0;
  max-height: 4.4rem;
  opacity: 1;
  overflow: hidden;
  padding: ${props.isIcon ? '0' : '0 3.5rem'};
  text-transform: ${props.green ? 'none' : 'uppercase'};
  transition-property: opacity;
  width: auto;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
  -webkit-font-smoothing: inherit;
  -webkit-backface-visibility: hidden;

  :active {
    outline: 0;
  }

  :hover {
    opacity: 0.75;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  ${props.isIcon
    ? `
    svg {
      width: 4rem;
      height: 4rem;
    }
  `
    : ''}
`;

const styledLikeText = (props) => css`
  background: transparent;
  border: 0.2rem solid ${props.theme.color.white};
  color: ${props.theme.color.brand};
  letter-spacing: 0.2em;
  padding: 0;
`;

const styledLikeLink = (props) => css`
  color: ${props.theme.color.brand};
  border: none;
  background: transparent;
  line-height: 1.8rem;
  letter-spacing: 0.05rem;
  text-decoration: underline;
  text-transform: none;
  padding: 0;

  :hover {
    opacity: 1;
  }
`;

const FILTERED_BUTTON_EMO_PROPS = new Set([
  'active',
  'filterBtn',
  'green',
  'handleonComplete',
  'handleOnSuccess',
  'handleOnPromise',
  'inverted',
  'isActive',
  'isIcon',
  'isLink',
  'isText',
  'outline',
  'promise',
  'styledLikeLink',
  'text'
]);

const StyledButton = styled('button', {
  shouldForwardProp: (prop) => !FILTERED_BUTTON_EMO_PROPS.has(prop)
})`
  ${ButtonReset}
  background-color: ${(props) => buttonColors(props).background};
  border: 0.2rem solid ${(props) => buttonColors(props).border};
  color: ${(props) => buttonColors(props).color};
  font-family: ${({ theme, green }) => (green ? theme.font.sans : theme.font.caption)};
  font-size: ${({ theme, green }) => (green ? theme.modularScale.eighteen : theme.modularScale.small)};
  transition-duration: ${(props) => props.theme.animation.default};
  transition-timing-function: ${(props) => props.theme.animation.easeOutQuad};

  ${(props) => (props.isText ? styledLikeText : '')}
  ${(props) => (props.styledLikeLink ? styledLikeLink : '')}
`;

const StyledLink = styled('a', {
  shouldForwardProp: (prop) => !FILTERED_BUTTON_EMO_PROPS.has(prop)
})`
  ${ButtonReset}
  text-decoration: none;
  background-color: ${(props) => buttonColors(props).background};
  border: 0.2rem solid ${(props) => buttonColors(props).border};
  color: ${(props) => buttonColors(props).color};
  font-family: ${(props) => props.theme.font.caption};
  font-size: ${({ theme, filterBtn }) => (filterBtn ? theme.modularScale.fourteen : theme.modularScale.small)};
  transition-duration: ${(props) => props.theme.animation.default};
  transition-timing-function: ${(props) => props.theme.animation.easeOutQuad};

  ${(props) => (props.isText ? styledLikeText : '')}

  span {
    line-height: 2rem;
  }
`;

class Button extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handlePromise = this.handlePromise.bind(this);
  }

  handleClick(e) {
    if (this.props.promise) {
      this.handlePromise();
    } else if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  handlePromise() {
    if (this.props.handleOnPromise !== undefined) {
      this.props.handleOnPromise(this.buttonRef);
    }

    this.props
      .promise()
      .then((res) => this.props.handleOnSuccess(this.buttonRef, res))
      .catch((error) => this.props.onError(this.buttonRef, error))
      .finally((res) => {
        if (this.props.handleonComplete !== undefined) {
          this.props.handleonComplete(this.buttonRef, res);
        }
        return false;
      });
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    const { onClick, inverted, ...otherProps } = this.props;
    return this.props.isLink && !this.props.disabled ? (
      <StyledLink {...this.props}>{this.props.text || this.props.children}</StyledLink>
    ) : (
      <StyledButton
        type="button"
        onClick={this.handleClick}
        ref={(node) => {
          this.buttonRef = node;
        }}
        inverted={inverted ?? undefined}
        data-test-id={this.props.dataTestId}
        {...otherProps}>
        {this.props.text || this.props.children}
      </StyledButton>
    );
  }
}

Button.defaultProps = {
  children: null,
  disabled: false,
  text: null,
  inverted: false,
  isIcon: false,
  isLink: false,
  isText: false,
  styledLikeLink: false,
  outline: false,
  promise: false,
  onClick: undefined,
  handleOnSuccess: undefined,
  onError: undefined,
  handleonComplete: undefined,
  handleOnPromise: undefined,
  dataTestId: undefined
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  isIcon: PropTypes.bool,
  isLink: PropTypes.bool,
  isText: PropTypes.bool,
  styledLikeLink: PropTypes.bool,
  outline: PropTypes.bool,
  text: PropTypes.string,
  promise: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  handleOnSuccess: (props, propName) =>
    props.promise && (props[propName] === undefined || typeof props[propName] !== 'function')
      ? new Error('Please provide an handleOnSuccess function')
      : null,
  onError: (props, propName) =>
    props.promise && (props[propName] === undefined || typeof props[propName] !== 'function')
      ? new Error('Please provide an onError function')
      : null,
  handleonComplete: PropTypes.func,
  handleOnPromise: PropTypes.func,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string
};

Button.whyDidYouRender = true;

export default Button;

export { buttonColors };
